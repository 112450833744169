import axios from "axios";

class NewsApiService {
  map(arg0) {
    throw new Error("Method not implemented.");
  }
  async getBySearch(search, resultPage) {
    // const token = import.meta.env.VITE_APP_YOUTUBE_API;
    const token = "pub_40513422e29f5249e7e2135c3e2a0d2174d71";
    // eslint-disable-next-line eqeqeq
    if (search == "") {
      search = "*";
    }
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get(
        `https://newsdata.io/api/1/news?apikey=${token}&q=${search}&country=id&language=id&category=health  `
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new NewsApiService();
