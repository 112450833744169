import React, { useState, useEffect } from "react";
import { Image } from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import Navbar from "../../components/Navbar";

interface NavigationButtonProps {
  direction: "next" | "prev";
  altText: string;
  onClick: () => void;
  disabled?: boolean;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  direction,
  altText,
  onClick,
  disabled,
}) => {
  const Icon = direction === "next" ? FaArrowRight : FaArrowLeft;

  return (
    <div
      className={`flex flex-1 gap-2.5 px-5 ${
        direction === "next" ? "flex-row-reverse" : ""
      } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
      onClick={onClick}
    >
      <Icon size={24} />
      <div className="my-auto">
        {direction === "next" ? t("next") : t("prev")}
      </div>
    </div>
  );
};

const RelaksasiPage: React.FC = () => {
  const [step, setStep] = useState(1);
  const [countdown, setCountdown] = useState(35);
  const [canProceed, setCanProceed] = useState(false);
  const MAX_STEP = 4; // ATUR MAX STEP NYAAAAAAAAAAAAAAAAA
  const navigate = useNavigate();

  // const { t, i18n } = useTranslation();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          setCanProceed(true);
          clearInterval(intervalId);
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [step]);

  useEffect(() => {
    if (step === MAX_STEP) {
      navigate("/finished");
    }
  }, [step, navigate, MAX_STEP]);

  const handleNextStep = () => {
    if (canProceed) {
      setStep((prevStep) => prevStep + 1);
      setCountdown(35);
      setCanProceed(false);
    }
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
    setCountdown(35);
    setCanProceed(false);
  };

  const getImageSource = (step: number) => {
    switch (step) {
      case 1:
        return "https://www.wikihow.com/images/thumb/6/60/Stretch-Step-6-Version-4.jpg/aid54461-v4-728px-Stretch-Step-6-Version-4.jpg.webp";
      case 2:
        return "https://www.wikihow.com/images/thumb/c/c3/Stretch-Step-8-Version-4.jpg/aid54461-v4-728px-Stretch-Step-8-Version-4.jpg.webp";
      case 3:
        return "https://www.wikihow.com/images/thumb/7/70/Stretch-Step-13-Version-3.jpg/aid54461-v4-728px-Stretch-Step-13-Version-3.jpg.webp";
      default:
        return "";
    }
  };

  const getTitle = (step: number) => {
    switch (step) {
      case 1:
        return "Peregangan Lengan";
      case 2:
        return "Peregangan Jari";
      case 3:
        return "Peregangan Kaki";
      default:
        return "";
    }
  };

  return (
    <>
      <Navbar />
      <section className="flex flex-col pt-5 pb-20 mx-auto w-full font-bold bg-white max-w-[480px] text-zinc-800">
        <header className="flex flex-col px-3.5 w-full">
          <Image
            src={getImageSource(step)}
            alt="Content image"
            className="w-full aspect-[1.44]"
          />
          <div className="mt-20 -mb-0.5 text-2xl">
            <p>
              {" "}
              {t("relax_1")} <em>{step}</em> :{" "}
            </p>
            <p>
              {" "}
              <br />{" "}
            </p>
          </div>
          <h1 className="box-border relative shrink-0 self-center mt-5 h-auto">
            <strong>{getTitle(step)}</strong>
          </h1>
          <div className="self-center mt-16 text-7xl text-center">
            <p>
              <strong>
                <em>{countdown}</em>
                <span className="ql-cursor">﻿</span>
              </strong>
            </p>
          </div>
        </header>
        <nav className="flex gap-5 justify-between items-start self-center mt-14 w-full text-base whitespace-nowrap max-w-[372px] ">
          {step > 1 && (
            <NavigationButton
              direction="prev"
              altText="Icon for previous page"
              onClick={handlePrevStep}
            />
          )}
          {step < MAX_STEP && (
            <NavigationButton
              direction="next"
              altText="Icon for next page"
              onClick={handleNextStep}
              disabled={!canProceed}
            />
          )}
        </nav>
      </section>
    </>
  );
};

export default RelaksasiPage;
