import clsx from "clsx";

interface Props {
  title: string;
  icon: React.ReactNode;
  link: string;
}

export default function Card3(props: Props) {
  return (
    <a
      className={clsx("flex flex-col justify-center items-center")}
      href={props.link}
    >
      <div className="text-2xl text-blue-800">{props.icon}</div>
      <h4 className="text-xs mt-2">{props.title}</h4>
    </a>
  );
}
