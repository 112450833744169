import clsx from "clsx";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";

export default function FirstAidDetail() {
  const { t, i18n } = useTranslation();
  const ImageCard =
    "https://images.unsplash.com/photo-1682686578601-e7851641d52c?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
  const ImageUser =
    "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=1364&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
  return (
    <>
      <Navbar />
      <div>
        <div
          className={clsx(
            "w-full h-40 rounded-xl bg-center bg-cover bg-no-repeat relative p-2 flex justify-start items-start"
          )}
          style={{ backgroundImage: `url(${ImageCard})` }}
        >
          {/* label */}
          <p className="bg-slate-200/60 p-2 rounded-lg text-white text-xs">
            Technology
          </p>
        </div>
        {/* description */}
        <div className="py-2 px-2">
          {/* title */}
          <h4 className={clsx("text-md font-bold  text-justify")}>
            How the metaverse could impact the world and the future
          </h4>
          {/* date and views */}
          <div
            className={clsx(
              "font-light text-slate-600 flex gap-2 mt-1 mb-3 text-xs"
            )}
          >
            <span>Jan 3, 2022</span>
            <span>•</span>
            <span>3344 views</span>
          </div>
          {/* user profile */}
          <div className={clsx("flex items-center gap-2")}>
            <div
              className="w-5 h-5 rounded-lg bg-center bg-cover bg-no-repeat relative"
              style={{ backgroundImage: `url(${ImageUser})` }}
            ></div>
            <span className="text-xs">By : Adi Ganteng</span>
          </div>
          {/* desc */}
          <div className="text-xs text-justify py-4">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
              dolore tempora voluptatem optio ipsam exercitationem doloremque
              ut, asperiores est veritatis velit ab dolorem vitae harum earum
              quod magnam nihil consequuntur!
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
              dolore tempora voluptatem optio ipsam exercitationem doloremque
              ut, asperiores est veritatis velit ab dolorem vitae harum earum
              quod magnam nihil consequuntur!
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
              dolore tempora voluptatem optio ipsam exercitationem doloremque
              ut, asperiores est veritatis velit ab dolorem vitae harum earum
              quod magnam nihil consequuntur!
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
              dolore tempora voluptatem optio ipsam exercitationem doloremque
              ut, asperiores est veritatis velit ab dolorem vitae harum earum
              quod magnam nihil consequuntur!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
