import Navbar from "../../components/Navbar";
import Card2 from "../../components/Card2";
import newsapi from "../../services/news-api";
import { FormEvent, useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface NewsItem {
  ai_region?: string; // Available in corporate plans
  ai_tag?: string; // Available in professional and corporate plans
  article_id: string;
  category: string[];
  content?: string; // Available in paid plans
  country: string[];
  creator: any; // Depends on the data type, assuming any for flexibility
  description: string;
  image_url: string;
  keywords: any; // Depends on the data type, assuming any for flexibility
  language: string;
  link: string;
  pubDate: string;
  sentiment?: string; // Available in professional and corporate plans
  sentiment_stats?: string; // Available in professional and corporate plans
  source_icon: string;
  source_id: string;
  source_priority: number;
  source_url: string;
  title: string;
  video_url?: string; // Depends on the availability, assuming it's optional
}

export default function FirstAid() {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [itemsFilter, setItemsFilter] = useState<any[]>([]);
  const [resultPage, setResultPage] = useState(1);
  const { t, i18n } = useTranslation();

  const filteredItems = async () => {
    try {
      const response = await newsapi.getBySearch(searchKeyword, resultPage);
      setItemsFilter(response.results);
      console.log(response.results);
    } catch (e) {
      alert("Error fetching data from API");
      console.error(e);
    }
  };

  const addPage = () => {
    setResultPage(resultPage + 1);
    filteredItems();
  };

  const submitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    filteredItems();
  };

  useEffect(() => {
    filteredItems();
  }, []);
  return (
    <div>
      <Navbar />
      <h1 className="text-xl font-semibold mb-5 mt-6">
        {t("first_aid_title")}
      </h1>
      <form className="flex items-center mb-5" onSubmit={submitForm}>
        <label htmlFor="simple-search" className="sr-only">
          {t("search")}
        </label>
        <div className="relative w-full flex gap-2">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            id="simple-search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 "
            placeholder={t("search_2")}
            required
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
          <Button colorScheme="blue" type="submit">
            {t("search")}
          </Button>
        </div>
      </form>
      <div className="grid grid-cols-1 gap-10">
        {itemsFilter.map((data: NewsItem) => (
          <Card2
            key={data.article_id}
            link={data.link}
            imageCard={data.image_url}
            category={data.category[0]}
            title={data.title}
            date={data.pubDate}
            author={data.source_url}
          />
        ))}
      </div>
      <div className="flex justify-center mt-5">
        {/* <button
          onClick={addPage}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-md px-5 py-2.5 text-center me-2 mb-2 "
        >
          {t("view_more_2")}
        </button> */}
      </div>
    </div>
  );
}
