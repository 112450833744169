import React from "react";
import { Link } from "react-router-dom";

interface Card4Props {
  judul: string;
  image: string;
  imageWidth?: number;
  href: string;
}

const Card4: React.FC<Card4Props> = ({
  judul,
  image,
  imageWidth = 250,
  href,
}) => {
  return (
    <Link to={href} className="w-full">
      <div className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 shadow hover:scale-105 transition">
        {image && (
          <div
            style={{
              backgroundImage: `url(${image})`,
              width: `100%`,
              height: `${imageWidth}px`,
            }}
            className="w-full h-[250px] bg-cover bg-center"
          ></div>
        )}
        <h5 className="mb-2 text-md font-bold tracking-tight text-gray-900 ">
          {judul}
        </h5>
      </div>
    </Link>
  );
};

export default Card4;
