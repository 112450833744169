import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./router/index";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import clsx from "clsx";
import i18n from "./services/il8n";

const Root = () => {
  const [statusLanguage, setStatusLanguage] = useState(true);

  useEffect(() => {
    const checkLanguage = () => {
      let language = localStorage.getItem("language");
      if (language === "id") {
        setStatusLanguage(false);
        localStorage.setItem("language", "id");
        i18n.changeLanguage("id");
      } else {
        setStatusLanguage(true);
        localStorage.setItem("language", "en");
        i18n.changeLanguage("en");
      }
    };

    checkLanguage();
  }, []);

  return (
    <React.StrictMode>
      <ChakraProvider>
        <div
          className={clsx(
            "container mx-auto max-w-2xl min-h-screen gap-2 relative",
            "px-2 pt-4 pb-28",
            "shadow"
          )}
        >
          <App />
        </div>
      </ChakraProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<Root />);

reportWebVitals();
