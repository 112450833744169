import React, { useState, useEffect, useRef } from 'react';
import './CustomDropdown.css'; 

interface SelectOption {
  label: string;
  value: string;
}

interface CustomDropdownProps {
  options: SelectOption[];
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  value,
  onChange,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (selectedValue: string) => {
    setIsOpen(false);
    onChange(selectedValue);
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className="select-selected" onClick={() => setIsOpen(!isOpen)}>
        {value || placeholder}
      </div>
      {isOpen && (
        <div className="select-items">
          {options.map(option => (
            <div
              key={option.value}
              className="select-option"
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
