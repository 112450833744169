import clsx from "clsx";
import { IoHome, IoArrowBack } from "react-icons/io5";
import { FaQuestion } from "react-icons/fa6";
import IndIcon from "../../assets/ind-icon.svg";
import EngIcon from "../../assets/eng-icon.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

export default function Navbar() {
  const [statusLanguage, setStatusLanguage] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const settingsSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const prevPage = () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };

  const { t, i18n } = useTranslation();

  // check language
  const checkLanguage = () => {
    let language = localStorage.getItem("language");
    if (language == "id") {
      setStatusLanguage(false);
      localStorage.setItem("language", "id");
      i18n.changeLanguage("id");
    } else {
      setStatusLanguage(true);
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    }
  };

  useEffect(() => {
    checkLanguage();
  }, []);

  // change language
  const changeLanguage = () => {
    // language
    // default id ( indonesian )
    let language = localStorage.getItem("language");
    if (language == "en") {
      setStatusLanguage(false);
      localStorage.setItem("language", "id");
      i18n.changeLanguage("id");
    } else {
      setStatusLanguage(true);
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    }
  };

  return (
    <>
      <div
        className={clsx(
          "shadow-inner p-2 fixed bottom-0 w-full -ml-2 max-w-2xl rounded-t-2xl flex justify-around bg-white z-20"
        )}
      >
        {/* item */}
        <a
          href="/"
          className="w-full flex flex-col items-center justify-center"
        >
          <div className="text-4xl bg-primary text-white w-12 h-12 rounded-xl p-3 flex items-center justify-center">
            <IoHome />
          </div>
          <p className="text-xs mt-1">{t("navbar_home")}</p>
        </a>
        {/* item */}
        <div
          className="w-full flex flex-col items-center justify-center cursor-pointer"
          onClick={prevPage}
        >
          <div className="text-4xl bg-primary text-white w-12 h-12 rounded-xl p-3 flex items-center justify-center">
            <IoArrowBack />
          </div>
          <p className="text-xs mt-1">{t("navbar_back")}</p>
        </div>
        {/* item */}
        <>
          <div
            className="w-full flex flex-col items-center justify-center"
            onClick={onOpen}
          >
            <div className="text-4xl bg-primary text-white w-12 h-12 rounded-xl p-3 flex items-center justify-center">
              <FaQuestion />
            </div>
            <p className="text-xs mt-1">{t("navbar_tutorial")}</p>
          </div>
          {/* item-modal */}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className="m-2">
              <ModalHeader>Tutorial</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <iframe
                  width="100%"
                  height="400"
                  src="https://www.youtube.com/embed/w2-dXQx34ok?si=Xxp8D2q9IhQUN2lo"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </ModalBody>

              <ModalFooter>
                {/* <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Close
                </Button> */}
                <br />
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
        {/* item */}
        {/* ind */}
        <div
          className="w-full flex flex-col items-center justify-center cursor-pointer"
          onClick={changeLanguage}
        >
          {!statusLanguage ? (
            <div className="flex justify-center items-center flex-col">
              <img src={IndIcon} alt="" className="w-full max-w-12" />
              <p className="text-[10px] mt-1">Ganti Bahasa</p>
            </div>
          ) : (
            <div className="flex justify-center items-center flex-col">
              <img src={EngIcon} alt="" className="w-full max-w-12" />
              <p className="text-[10px] mt-1">Change Language</p>
            </div>
          )}
        </div>
        {/* eng */}
      </div>
    </>
  );
}
