import React, { useState, useEffect, FormEvent } from "react";
import Card4 from "../../components/Card4";
import youtube from "../../services/youtube";
import { Button } from "@chakra-ui/react";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";

interface Item {
  snippet: {
    title: string;
    thumbnails: {
      high: {
        url: string;
      };
    };
  };
  id: {
    videoId: string;
  };
}

function Index({ match }: any) {
  const [searchKeyword, setSearchKeyword] = useState("Karoke ");
  const [itemsFilter, setItemsFilter] = useState<any[]>([]);
  const [resultPage, setResultPage] = useState(1);
  const { t, i18n } = useTranslation();

  const filteredItems = async () => {
    try {
      const response = await youtube.getBySearch(searchKeyword, resultPage);
      setItemsFilter(response.items);
    } catch (e) {
      alert("Error fetching data from API");
      console.error(e);
    }
  };

  const addPage = () => {
    setResultPage(resultPage + 1);
    filteredItems();
  };

  const submitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    filteredItems();
  };

  useEffect(() => {
    filteredItems();
  }, []);

  return (
    <div>
      <Navbar />
      <form className="flex items-center mb-5" onSubmit={submitForm}>
        <label htmlFor="simple-search" className="sr-only">
        {t("search")}
        </label>
        <div className="relative w-full flex gap-2">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            id="simple-search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 "
            placeholder={t("search_2")}
            required
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
          <Button colorScheme="blue" type="submit">
          {t("search")}
          </Button>
        </div>
      </form>

      <div className="w-full gap-4 flex flex-col">
        {itemsFilter.map((item: Item, i: number) => (
          <div key={i} className="w-full">
            <Card4
              judul={item.snippet.title}
              href={`/karoke/${item.id.videoId}/${item.snippet.title}`}
              image={item.snippet.thumbnails.high.url}
            />
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-5">
        <button
          onClick={addPage}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-md px-5 py-2.5 text-center me-2 mb-2 "
        >
          {t("view_more")}
        </button>
      </div>
    </div>
  );
}

export default Index;
