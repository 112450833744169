import React, { useEffect, useState } from "react";
import axios from "axios";
import * as cheerio from "cheerio";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { Chart } from "primereact/chart";

interface ScrapedDataItem {
  id: number;
  img: string;
  title: string;
  description: string;
}

export default function PasangLautDashboard() {
  const [scrapedData1, setScrapedData1] = useState("");
  const [scrapedData2, setScrapedData2] = useState<ScrapedDataItem[]>([]);
  const timeAllForChart: any = [];
  const tideAllForChart: any = [];
  const [loading, setLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState("");
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const { t, i18n } = useTranslation();
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");
      setCurrentTime(`${hours}:${minutes}:${seconds}`);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // getWebsiteData1 -> description predictionn
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let iLoops = 2;
  const getWebsiteData1 = async () => {
    try {
      const url = "https://tideking.com/Indonesia/Bali/Tanjung-Benoa/";
      const response = await axios.get(url);
      const html = response.data;
      const $ = cheerio.load(html);

      // 1.
      // Select the figure element with class "card-body"
      const figureElement = $(
        "#layout-wrapper > div.main-content > div > div > div.row > main > section:nth-child(1) > div > figure > figcaption"
      );

      // Get the HTML content of the figure element
      const figureHtml: any = figureElement.html();

      // Update the state with the HTML content
      setScrapedData1(figureHtml);
      // console.log(figureElement);

      // 2.
      const scrapedData2: any = [];
      $(".col-6.col-sm-6.col-md-3.col-sm-6.text-center.pt-2.pb-4 div").each(
        (index, element) => {
          const img = $(element).find("img").attr("src");
          const title = $(element).find("h3").text();
          const description = $(element).find("h5").text();
          scrapedData2.push({
            id: index,
            img: "https://tideking.com/" + img,
            title: title,
            description: description,
          });
        }
      );

      setScrapedData2(scrapedData2);

      // 3. get time chart
      // eslint-disable-next-line no-self-assign
      for (iLoops = iLoops; iLoops <= 5; iLoops++) {
        // time
        const item: any = $(
          "#layout-wrapper td:nth-child(" + iLoops + ")"
        ).html();
        const rewrite = item.slice(0, 7); // Extract first 6 characters excluding '<'
        timeAllForChart.push(rewrite);
        // tide
        const rewrite2: any = parseFloat(item.slice(41, 44));
        tideAllForChart.push(rewrite2);
      }

      // ----------

      console.log(timeAllForChart);
      console.log(tideAllForChart);

      setLoading(false);
    } catch (error) {
      console.error("Error scraping data:", error);
    }
  };

  useEffect(() => {
    // chart js
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: timeAllForChart,
      datasets: [
        {
          label: "Gelombang Pasang Surut Air Laut",
          data: tideAllForChart,
          fill: true,
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          tension: 0.4,
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
    getWebsiteData1();
  }, []);

  return (
    <div>
      <Navbar />
      <h1 className="font-bold text-2xl text-center mb-10">
        {t("tide-title1")}
      </h1>

      {/* content */}
      {loading ? (
        <div role="status" className="flex justify-center mb-5">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div>
          {/* Currents */}
          <div className="mb-10">
            <p className="mb-4 font-bold text-2xl">{t("tide-title2")}</p>
            <span>{currentTime}</span>
          </div>
          {/* Tide */}
          <div className="mb-10">
            <p className="mb-4 font-bold text-2xl my-4">{t("tide-title3")}</p>
            <Chart type="line" data={chartData} options={chartOptions} />
            <br />
            <div dangerouslySetInnerHTML={{ __html: scrapedData1 }} />{" "}
          </div>
          {/* Weather */}
          <div>
            <p className="mb-4 font-bold text-2xl my-4">{t("tide-title4")}</p>
            <div className="grid  grid-cols-2 gap-10 mb-8">
              {scrapedData2.map((item, index) => (
                <div
                  className="bg-white border border-gray-200 rounded-lg shadow"
                  key={index}
                >
                  <img
                    className="rounded-t-lg h-20 w-full"
                    src={item.img}
                    alt="image"
                  />
                  <div className=" text-center">
                    <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {item.title}
                    </h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="pb-4 flex justify-center">
        <a
          className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
          href="https://tideking.com/Indonesia/Bali/Tanjung-Benoa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Support : tideking.com
        </a>
      </div>
    </div>
  );
}
