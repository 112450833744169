import clsx from "clsx";

interface CardProps {
  imageCard: string | undefined;
  category: string;
  title: string;
  date: string;
  author: string;
  link: string;
}

const Card2: React.FC<CardProps> = ({
  imageCard,
  category,
  title,
  date,
  author,
  link,
}) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div
        className={clsx(
          "shadow-shadow1 rounded-xl",
          "transition hover:shadow-none"
        )}
      >
        <div
          style={{ backgroundImage: `url("${imageCard}")` }}
          className={clsx(
            "w-full rounded-xl relative p-2 flex justify-start items-start",
            {
              "h-40 bg-center bg-cover bg-no-repeat ": imageCard,
            }
          )}
        >
          {/* label */}
          {imageCard ? (
            <p className="bg-slate-600 p-2 rounded-lg text-white text-xs">
              {category}
            </p>
          ) : (
            ""
          )}
        </div>
        {/* description */}
        <div className="py-2 px-2">
          {/* title */}
          <h4 className={clsx("text-md font-bold  text-justify")}>{title}</h4>
          {/* date and views */}
          <div
            className={clsx(
              "font-light text-slate-600 flex gap-2 mt-1 mb-3 text-xs"
            )}
          >
            <span>{date}</span>
          </div>
          {/* user profile */}
          <div className={clsx("flex items-center gap-2")}>
            <span className="text-xs">By : {author}</span>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Card2;
