import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FinishedPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <div style={{ textAlign: "center", padding: "1.5rem" }}>
      <Text
        style={{ fontSize: "3xl", fontWeight: "bold", marginBottom: "1rem" }}
      >
          {t("congrats_1")}
      </Text>
      <Image
        src="https://cdn.dribbble.com/users/75440/screenshots/12998251/media/0e9ccad928e1fcaa3924dea6e26937c2.gif"
        alt="Congratulations"
        style={{ maxWidth: "100%", marginBottom: "1rem" }}
      />
      <Text style={{ fontSize: "lg", marginBottom: "1rem" }}>
      {t("congrats_2")}
      </Text>
      <button
        className="bg-primary"
        style={{
          padding: "0.5rem 1rem",
          color: "white",
          border: "none",
          borderRadius: "0.25rem",
          cursor: "pointer",
          transition: "background-color 0.3s",
        }}
        onClick={handleGoBack}
      >
       {t("congrats_3")}
      </button>
    </div>
  );
};

export default FinishedPage;
