import clsx from "clsx";

interface Props {
  link: string;
  title: string;
  icon: React.ReactNode;
}

export default function Card1(props: Props) {
  return (
    <a
      href={props.link}
      className={clsx(
        " shadow-shadow1 rounded-lg",
        "flex flex-col justify-center items-center py-4 text-primary font-semibold cursor-pointer",
        "transition hover:text-white hover:bg-primary"
      )}
    >
      <div className="text-5xl ">{props.icon}</div>
      <h4 className=" text-xs mt-1 text-center h-6">{props.title}</h4>
    </a>
  );
}
