import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";

export default function Detail() {
  const { id_item, title } = useParams();

  useEffect(() => {
    // setid_item(match.params.id_item);
    // setTitle(match.params.title);
  }, []);

  return (
    <>
      <Navbar />
      <div className="w-full gap-4 flex flex-col">
        <iframe
          width="100%"
          height="315"
          src={`https://youtube.com/embed/${id_item}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        <p className="text-xl font-bold">{title}</p>
      </div>
    </>
  );
}
