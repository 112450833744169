import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "../services/il8n";
import { Box, Image, Text } from "@chakra-ui/react";

// -- Page
import Home from "../views/Home/index";
import FirstAid from "../views/FirstAid";
import Tide from "../views/Tide";
import JournalingForm from "../views/Journaling/JournalingFormPage";
import JournalingPage from "../views/Journaling/JournalingPage";
import JournalingDetailPage from "../views/Journaling/JournalingDetailPage";
import VideoMusic from "../views/VideoMusic/Index";
import VideoMusicDetail from "../views/VideoMusic/Detail";
import Karoke from "../views/Karoke/Index";
import KarokeDetail from "../views/Karoke/Detail";
import FirstAidDetail from "../views/FirstAid/detail";
import Countdown from "../views/Relaksasi/Countdown";
import RelaksasiPage from "../views/Relaksasi/Relaksasi";
import FinishedPage from "../views/Relaksasi/Finished";
import { useEffect, useState } from "react";
import CountdownGap from "../components/CountdownGap";
import { useTranslation } from "react-i18next";
import Navbar from "../components/Navbar";

const handleSubmit = (formData: any) => {
  console.log("Form submitted:", formData);
};

export default function App() {
  const { t, i18n } = useTranslation();
  const [countdown, setCountdown] = useState<number>(() => {
    const storedCountdown = localStorage.getItem("countdown");
    if (storedCountdown !== null) {
      return parseInt(storedCountdown, 10);
    } else {
      return 7200; // Default countdown value (2 minutes)
    }
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          const newCountdown = prevCountdown - 1;
          if (newCountdown === 0) {
            clearInterval(timer);
            if (window.location.pathname !== "/finishedaccess") {
              window.location.href = "/finishedaccess";
            }
          }
          return newCountdown;
        } else {
          // Countdown already reached 0, no need to decrement further
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    localStorage.setItem("countdown", countdown.toString());
  }, [countdown]);
  

  return (
    <BrowserRouter>
      <Routes>
      {countdown === 0 ? (
          <>
            <Route path="/finishedaccess" element={<FinishedAccess />} />
            <Route path="/*" element={<Navigate to="/finishedaccess" />} />
          </>
        ) : (
          <>
        <Route path="/" element={<Home />} />
        {/* first-aid */}
        <Route path="/first-aid" element={<FirstAid />} />
        <Route path="/first-aid/:title" element={<FirstAidDetail />} />
        {/* tide */}
        <Route path="/tide" element={<Tide />} />
        {/*  */}
        <Route path="/journaling" element={<JournalingPage />} />
        <Route
          path="/journaling/:id"
          element={<JournalingDetailPage onSubmit={handleSubmit} />}
        />
        <Route
          path="/journaling-form"
          element={<JournalingForm onSubmit={handleSubmit} />}
        />
        {/*  */}
        <Route path="/video_music" element={<VideoMusic />} />
        <Route
          path="/video_music/:id_item/:title"
          element={<VideoMusicDetail />}
        />
        {/*  */}
        <Route path="/karoke" element={<Karoke />} />
        <Route path="/karoke/:id_item/:title" element={<KarokeDetail />} />
        {/*  */}
        <Route
          path="/countdown"
          element={
            <Countdown
              timeInSeconds={10}
              redirectPath="/relax"
              imageUrl="https://cdn.dribbble.com/users/68398/screenshots/5907827/yoga.gif"
            />
          }
        />
        <Route path="/relax" element={<RelaksasiPage />} />
        <Route path="/finished" element={<FinishedPage />} />
        </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

function FinishedAccess() {
  const { t, i18n } = useTranslation();
  const [countdownGap, setCountdownGap] = useState<number>(21600); // Countdown in "/finishedaccess"
  const [currentTime, setCurrentTime] = useState(new Date());
  const [chances, setChances] = useState<number>(() => {
    const storedChances = localStorage.getItem("chances");
    return storedChances ? parseInt(storedChances, 10) : 1; // Default to 1 if chances not found in local storage
  }); 
  
  useEffect(() => {
    // Function to reset chances, countdown, and countdownGap at midnight
    function resetChancesCountdownAndCountdownGapAtMidnight() {
      const now = new Date();
      const storedLastVisitDateStr = localStorage.getItem("lastVisitDate");
      const storedLastVisitDate = storedLastVisitDateStr ? new Date(storedLastVisitDateStr) : null;
  
      // Check if stored date exists and if it's not equal to the current date
      if (!storedLastVisitDate || storedLastVisitDate.toDateString() !== now.toDateString()) {
        // Date has changed, reset chances, countdown, and countdownGap
        localStorage.setItem("lastVisitDate", now.toDateString()); // Record the current date as the last visit date
        localStorage.setItem("chances", "1"); // Reset chances to 1
        localStorage.setItem("countdown", "7200"); // Reset countdown to 7200 seconds
        setCountdownGap(0); // Reset countdownGap to 0
        window.location.href = "/"; // Redirect to home
      }
    }
  
    // Call the function initially to handle the scenario where the date changes while the app is loaded
    resetChancesCountdownAndCountdownGapAtMidnight();
  
    // Set up an interval to check for date changes every minute
    const interval = setInterval(resetChancesCountdownAndCountdownGapAtMidnight, 60000);
  
    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, []);
  
  
  
  
  
  
  
  
  

  // useEffect(() => {
  //   // Reset chances to 1 and countdown to 7200 at midnight
  //   function resetChancesAtMidnight() {
  //     const now = new Date();
  //     const tomorrow = new Date(now);
  //     tomorrow.setDate(now.getDate() + 1);
  //     tomorrow.setHours(0, 0, 0, 0);
    
  //     const millisecondsUntilMidnight = tomorrow.getTime() - now.getTime();
    
  //     const resetChancesTimer = setTimeout(() => {
  //       localStorage.setItem("chances", "1"); // Reset chances to 1
  //       setChances(1);
    
  //       localStorage.setItem("countdown", "120"); // Reset countdown to 7200
  //       setCountdownGap(0); // Reset countdownGap to 0
    
  //       window.location.href = "/"; // Redirect to home
  //     }, millisecondsUntilMidnight);
    
  //     return () => clearTimeout(resetChancesTimer);
  //   };
  
  //   // Set up timer to reset chances and countdown at midnight
  //   const now = new Date();
  //   const millisecondsUntilMidnight = new Date(
  //     now.getFullYear(),
  //     now.getMonth(),
  //     now.getDate() + 1,
  //     0,
  //     0,
  //     0,
  //     0
  //   ).getTime() - now.getTime();
  
  //   const resetChancesTimer = setTimeout(resetChancesAtMidnight, millisecondsUntilMidnight);
  
  //   return () => clearTimeout(resetChancesTimer);
  // }, []);

  useEffect(() => {
    if (chances === 0) {
      return; // Do not start countdown if chances are zero
    }

    // Start countdown timer
    const timer = setInterval(() => {
      setCountdownGap((prevCountdown) => {
        const newCountdown = prevCountdown - 1;
        if (newCountdown === 0) {
          clearInterval(timer);
          localStorage.setItem("countdown", "7200"); // Reset countdown to 7200
          localStorage.setItem("chances", "0"); // Set chances to zero after countdown reaches zero
          setChances(0); 
          window.location.href = "/"; // Redirect to home
        }
        return newCountdown;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [chances]);

  useEffect(() => {
    // Update current time every second
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);


  return (
    <div>
      <>
      <Navbar />
      </>
      <h1>{t("countdowngap")}: {countdownGap}</h1>
      <h1>{t("chances")}: {chances}</h1>
      <div style={{ textAlign: "center", padding: "1.5rem" }}>
        <p>{t("current_time")}: {currentTime.toLocaleTimeString()}</p>
        <p style={{ fontSize: "3xl", fontWeight: "bold", marginBottom: "1rem" }}>
        {t("expired_text")}
        </p>
        <img
          src="https://cdn.dribbble.com/users/974517/screenshots/6096041/v5mt_sad_boy_nok_dribbble.gif"
          alt="Congratulations"
          style={{ maxWidth: "100%", marginBottom: "1rem" }}
        />
        <p style={{ fontSize: "lg", marginBottom: "1rem" }}>
        {t("expired_text_2")}
        </p>
      </div>
    </div>
    
  );
}