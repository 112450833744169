import clsx from "clsx";
import Navbar from "../../components/Navbar";
import Logo1 from "../../assets/logo1.svg";
import Icon1 from "../../assets/icon1.png";
import { Button } from "@chakra-ui/react";
import { IoCall } from "react-icons/io5";
import Card1 from "../../components/Card1";
import Card3 from "../../components/Card3";
import { GiStabbedNote } from "react-icons/gi";
import { FaFirstAid } from "react-icons/fa";
import { GiAtSea } from "react-icons/gi";
import { MdLibraryMusic } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { GiMeditation } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import { GiMusicalScore } from "react-icons/gi";

export default function Home() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Navbar />
      {/* content */}
      <div className="flex gap-3 items-center">
        <img src={Logo1} alt="" />
        <div>
          <h2 className="font-semibold text-md">{t("home_title_1")}</h2>
          <p className="italic font-semibold text-blue-900 -mt-1 text-xs">
            {t("home_title_4")}
          </p>
        </div>
      </div>
      {/* banner */}
      <div
        className={clsx(
          "w-full rounded-3xl px-4 my-5 flex shadow-shadow1",
          "bg-gradient-to-r to-[#1E89EF] from-[#164DE4]",
          "flex justify-evenly gap-5"
        )}
      >
        {/* left */}
        <div className="text-white w-1/2 py-3 flex flex-col justify-between">
          <div>
            <h1 className="font-bold text-lg  ">
              {t("home_title_2")},
              <br />
              {t("home_title_3")}!
            </h1>
            <p className="mt-2 text-sm"> {t("home_title_6")}</p>
          </div>
          <a href="#contact">
            <Button
              leftIcon={<IoCall />}
              className="bg-white mt-6 font-bold !text-xs !p-0 !w-32"
            >
              {t("home_title_5")}
            </Button>
          </a>
        </div>
        {/* right */}
        <div className="w-1/2 flex justify-center ">
          <img src={Icon1} alt="" className="w-64 h-40" />
        </div>
      </div>
      {/* feature */}
      <div className="mt-3">
        <h2 className="text-xl mb-3">{t("home_title_7")}</h2>
        <div className="grid grid-cols-3 gap-8 items-center justify-center">
          <Card1
            link={"/journaling"}
            title={t("home_title_8")}
            icon={<GiStabbedNote />}
          />
          <Card1
            link={"/first-aid"}
            title={t("home_title_9")}
            icon={<FaFirstAid />}
          />
          <Card1
            link={"/video_music"}
            title={t("home_title_10")}
            icon={<MdLibraryMusic />}
          />
          <Card1 link={"/tide"} title={t("home_title_11")} icon={<GiAtSea />} />
          <Card1
            link={"/countdown"}
            title={t("home_title_12")}
            icon={<GiMeditation />}
          />
          <Card1
            link={"/karoke"}
            title={t("home_title_15")}
            icon={<GiMusicalScore />}
          />
        </div>
      </div>
      {/* question */}
      <div className="mt-6">
        <h2 className="text-xl mb-4">
          {t("home_title_13")}? <br /> {t("home_title_14")}
        </h2>
        <div className="grid grid-cols-3 gap-4 px-3" id="contact">
          <Card3
            title={"+6281916451200"}
            icon={<BsWhatsapp />}
            link={"https://wa.me/6281916451200"}
          />
         <Card3 title={"@officialcopstress"} icon={<FaInstagram />} link={"https://www.instagram.com/officialcopstress/?hl=id"} />
        <Card3 title={"copstress.official"} icon={<MdMail />} link={"mailto:copstress.official@gmail.com"} />
        </div>
      </div>
    </>
  );
}
