import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Textarea, Button, Icon, Box } from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";
import clsx from "clsx";
import Cookies from "js-cookie";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";

interface NoteFormProps {
  onSubmit: (formData: FormData) => void;
}

interface FormData {
  id: string;
  tanggal: number | string;
  bulan: number | string;
  tahun: number | string;
  namaCatatan: string;
  isiCatatan: string;
}

const NoteForm: React.FC<NoteFormProps> = ({ onSubmit }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    id: "",
    tanggal: "",
    bulan: "",
    tahun: "",
    namaCatatan: "",
    isiCatatan: "",
  });
  const [entries, setEntries] = useState<FormData[]>([]);

  useEffect(() => {
    // Retrieve saved entries from localStorage
    const savedEntries = localStorage.getItem("journalEntries");
    if (savedEntries) {
      setEntries(JSON.parse(savedEntries));
    }
  }, []);


  const generateOptions = (start: number, end: number) => {
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  };

  const tanggalOptions = generateOptions(1, 31);
  const bulanOptions = generateOptions(1, 12);
  const tahunOptions = generateOptions(2000, new Date().getFullYear());

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const newEntry = { ...formData, id: Date.now().toString() };
    const updatedEntries = [...entries, newEntry];
    setEntries(updatedEntries);
    // Save entries to localStorage
    localStorage.setItem("journalEntries", JSON.stringify(updatedEntries));
    onSubmit(formData);
    navigate("/journaling");
  };

  return (
    <>
      <Navbar />
      <form onSubmit={handleSubmit}>
        <h1 className="text-xs">{t("date")}: </h1> <br></br>
        <div className="mb-4 flex">
        <CustomDropdown
            options={tanggalOptions.map(option => ({ label: option.toString(), value: option.toString() }))}
            value={formData.tanggal.toString()}
            onChange={(value) => handleSelectChange('tanggal', value)}
            placeholder={t("date")}
          />
          <CustomDropdown
            options={bulanOptions.map(option => ({ label: option.toString(), value: option.toString() }))}
            value={formData.bulan.toString()}
            onChange={(value) => handleSelectChange('bulan', value)}
            placeholder={t("months")}
          />
          <CustomDropdown
            options={tahunOptions.map(option => ({ label: option.toString(), value: option.toString() }))}
            value={formData.tahun.toString()}
            onChange={(value) => handleSelectChange('tahun', value)}
            placeholder={t("year")}
          />
        </div>
        <div className="mb-4">
          <InputArea
            label={t("note_title")}
            name="namaCatatan"
            placeholder={t("note_title")}
            value={formData.namaCatatan}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-6">
  <h1 className="text-xs">{t("note_text")}</h1>
  <Textarea
    name="isiCatatan"
    placeholder={t("note_text")}
    value={formData.isiCatatan}
    onChange={handleInputChange}
    className="mt-1 w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
  />
</div>


        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-primary hover:bg-green-700 text-white px-4 py-2 rounded-md flex items-center justify-center transition duration-300 ease-in-out"
          >
            <Icon as={FaCheck} className="mr-2" />
            {t("finish")}
          </button>
        </div>
      </form>
    </>
  );
};

interface InputAreaProps {
  label: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

const InputArea: React.FC<InputAreaProps> = ({
  label,
  name,
  placeholder,
  value,
  onChange,
}) => (
  <div className="flex flex-col mt-2.5">
    <label className="w-full text-xs leading-4">{label}</label>
    <input
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={clsx(
        "w-full",
        "font-medium",
        "py-3.5",
        "pr-16",
        "pl-3",
        "mt-2.5",
        "rounded-2xl",
        "border",
        "border-gray-300",
        "leading-[150%]",
        "text-black"
      )}
      aria-label={label}
    />
  </div>
);

export default NoteForm;
