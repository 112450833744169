import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar";

interface CountdownProps {
  timeInSeconds: number;
  redirectPath: string;
  imageUrl: string; // New prop for image URL
}

const Countdown: React.FC<CountdownProps> = ({
  timeInSeconds,
  redirectPath,
  imageUrl,
}) => {
  const { t, i18n } = useTranslation();
  const [seconds, setSeconds] = useState(timeInSeconds);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      navigate(redirectPath);
    }
  }, [seconds, navigate, redirectPath]);

  const formatTime = (time: number) => {
    const remainingSeconds = time % 60;
    return remainingSeconds < 10
      ? `0${remainingSeconds}`
      : remainingSeconds.toString();
  };

  return (
    <>
      <Navbar />
      <Box textAlign="center" fontSize="4xl" fontWeight="bold" p={4}>
        <img
          src={imageUrl}
          alt="Countdown Image"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: "auto",
            height: "auto",
          }}
        />
        {t("countdown_1")}!! <br />
        {formatTime(seconds)}
      </Box>
    </>
  );
};

export default Countdown;
