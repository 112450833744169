import axios from "axios";

class YoutubeService {
  async getBySearch(search, maxResults) {
    // const token = import.meta.env.VITE_APP_YOUTUBE_API;
    const token = "AIzaSyB9VoYbfRNZQnp_1QsFWawAUv2qdPeKTi0";
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?q=${search}&part=snippet&maxResults=${maxResults}&key=${token}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new YoutubeService();
