import React, { FC, useState, useEffect } from "react";
import { FiPlus, FiBell } from "react-icons/fi";
import { Link, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar";
import notificationSound from "./mixkit-musical-alert-notification-2309.mp3";


interface IconProps {
  icon: FC<{ className?: string }>;
  className?: string;
}

interface DateInfoCardProps {
  id: string;
  date: string;
  infoText: string;
  icon: FC<{ className?: string }>;
}

interface FormData {
  id: string;
  tanggal: number | string;
  bulan: number | string;
  tahun: number | string; 
  namaCatatan: string;
  isiCatatan: string;
}

const formatDate = (tanggal: number | string, bulan: number | string, tahun: number | string): string => {
  const months = [
    "Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
  ];

  const day = typeof tanggal === 'string' ? parseInt(tanggal, 10) : tanggal;
  const month = typeof bulan === 'string' ? parseInt(bulan, 10) : bulan;

  return `${day} ${months[month - 1]} ${tahun}`;
};

const IconComponent: FC<IconProps> = ({ icon: Icon, className }) => {
  return <Icon className={className} />;
};

const DateInfoCard: FC<DateInfoCardProps> = ({ id, date, infoText, icon }) => {
  const navigate = useNavigate(); 

  const handleClick = () => {
    navigate(`/journaling/${id}`);
  };

  return (
    <section className="flex flex-col py-5 pr-20 pl-7 mt-5 mr-2.5 ml-3.5 font-medium shadow-md leading-[100%] rounded-lg" onClick={handleClick}>
      <header className="flex gap-5 justify-between items-start self-start text-2xl tracking-tight text-black">
        <IconComponent icon={icon} className="ml-0 w-6 h-6" />
        <div className="flex-auto ml-5">{date}</div>
      </header>
      <p className="ml-16 mt-2 text-xs tracking-tight text-zinc-500">{infoText}</p>
    </section>
  );
};

const ToastNotification: FC<{ entryName: string }> = ({ entryName }) => {
  useEffect(() => {
    const audio = new Audio(notificationSound);
    audio.load();
  }, []);
  
  return (
    <div id="toast-simple" className="fixed top-0  z-50 flex items-center justify-center w-full max-w-md p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow :text-gray-400 " role="alert">
      <svg className="w-8 h-8 text-blue-600 rotate-45" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"/>
      </svg>
      <div className="ps-4 text-lg font-semibold">{entryName}</div>
    </div>
  );
};




const JournalingPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [entries, setEntries] = useState<FormData[]>([]);
  const [showNotification, setShowNotification] = useState(false);
  

  useEffect(() => {
    // Retrieve saved entries from localStorage
    const savedEntries = localStorage.getItem("journalEntries");
    if (savedEntries) {
      setEntries(JSON.parse(savedEntries));
    }
  }, []);
  
const getCurrentDate = (): string => {
  const daysEn = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const daysId = [
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
  ];

  const monthsEn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthsId = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const currentDate = new Date();
  const dayIndex = currentDate.getDay();
  const date = currentDate.getDate();
  const monthIndex = currentDate.getMonth();
  const year = currentDate.getFullYear();

  const days = i18n.language === "en" ? daysEn : daysId;
  const months = i18n.language === "en" ? monthsEn : monthsId;

  const day = days[dayIndex];
  const month = months[monthIndex];

  return `${day}, ${date} ${month} ${year}`;
};

const formatDate = (
  tanggal: number | string,
  bulan: number | string,
  tahun: number | string
): string => {
  const monthsEn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthsId = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const months = i18n.language === "en" ? monthsEn : monthsId;

  const day = typeof tanggal === "string" ? parseInt(tanggal, 10) : tanggal;
  const month = typeof bulan === "string" ? parseInt(bulan, 10) : bulan;

  return `${day} ${months[month - 1]} ${tahun}`;
};
  
useEffect(() => {
  const currentDate = new Date();
  const matchingEntries = entries.filter((entry) => {
    const entryDate = new Date(
      currentDate.getFullYear(),
      parseInt(entry.bulan.toString()) - 1,
      parseInt(entry.tanggal.toString())
    );
    return (
      entryDate.getDate() === currentDate.getDate() &&
      entryDate.getMonth() === currentDate.getMonth()
    );
  });

  if (matchingEntries.length > 0) {
    setShowNotification(true);
    // Automatically hide notification after 5 seconds
    const timer = setTimeout(() => {
      setShowNotification(false);
    }, 5000);
    return () => clearTimeout(timer);
  }
}, [entries]);

useEffect(() => {
  if (showNotification) {
    const audio = new Audio(notificationSound);
    audio.play();
    // Automatically hide notification after 5 seconds
    const timer = setTimeout(() => {
      setShowNotification(false);
      audio.pause();
      audio.currentTime = 0;
    }, 5000);
    return () => clearTimeout(timer);
  }
}, [showNotification]);



  return (
    <main>
      <Navbar />
      <header className="flex flex-col px-3.5 w-full text-2xl font-semibold text-white">
        <h1 className="font-medium text-black whitespace-nowrap">
          {getCurrentDate()}
        </h1>
        <div className="flex gap-0.5 self-start px-2 py-2.5 mt-6 text-sm leading-7 whitespace-nowrap bg-primary rounded-lg">
          <Link to="/journaling-form" className="flex gap-0.5">
            <IconComponent icon={FiPlus} className="my-auto w-6 h-6" />
            <span className="grow">{t("add_entry")}</span>
          </Link>
        </div>
      </header>
      <br />
      <section>
  {showNotification && <ToastNotification entryName={entries[0]?.namaCatatan || ''} />} {/* Assuming entries is not empty */}
  {entries.map((entry) => (
    <DateInfoCard
      key={entry.id}
      id={entry.id}
      date={formatDate(entry.tanggal, entry.bulan, entry.tahun)}
      infoText={entry.namaCatatan}
      icon={FiBell}
    />
  ))}
</section>

    </main>
  );
};

export default JournalingPage;
